<template>
    <section class="input-container" :class="disabled && 'disabled'">
        <label> {{ title }}</label>
        <div class="container">
            <div class="yes" :class="value ? 'active' : 'inactive'" @click="select(true)">Yes</div>
            <div class="separator"></div>
            <div class="no" :class="value ? 'inactive' : 'active'" @click="select(false)">No</div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['modelValue', 'title', 'disabled'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            },
        },
    },
    methods: {
        select(val) {
            if (this.value === val) {
                this.value = !val;
            } else {
                this.value = val;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.input-container {
    label {
       line-height: 18px;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        padding-bottom: 3.4px;
    }
    div {
        height: 100%;
        font-size: 12px;
    }
    .container {
        display: flex;
        width: 100px;
        height: 41.17px;
        background: #eeeeee;
        border-radius: 4px;
    }
    .no,
    .yes {
        width: 50px;
        color: rgb(62, 62, 62);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
    .yes {
        border-right: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .no {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .active {
        color: white;
        background: #0068d4;
        &:hover {
            background: #0063d5 !important;
        }
    }

    .inactive {
        &:hover {
            background: #d7d7d7 !important;
        }
    }
}

.separator {
    background: rgb(135, 135, 135);
    height: 100%;
    width: 1px;
}

.yes {
    border: 1px solid rgb(135, 135, 135);
    border-right: 0;
}
.no {
    border: 1px solid rgb(135, 135, 135);
    border-left: 0;
}

section.disabled {
    * {
        pointer-events: none;
    }
    .active {
        background: rgba(177, 177, 177, 1);
    }
}
</style>
