<template>
  <Toast/>
   <Header />
  <div class="flex flex-grow">
    <Sidebar />
    <div class="w-full flex-grow flex flex-col content-container">
               <slot></slot>
    </div>
  </div>
</template>
<script>
import Header from './Header';
import Sidebar from './Sidebar';
import Toast from './Toast';

export default {
  components: { Header, Sidebar, Toast },

   mounted() {
     this.$store.dispatch('policies/types');
  }
};
</script>

<style scoped>
 .content-container {
   height: calc(100vh - 55.770px);
 }
</style>
