<template>
    <section :class="disabled && 'disabled'">
        <label>{{ title }}</label>
        <div class="container flex flex-row items-stretch">
            <input v-model="value" @blur="sanitize" :disabled="disabled" />
            <div>
                <div class="icon">
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.984375 3.74497C0.984375 2.65936 1.29263 1.8217 1.90915 1.23198C2.53908 0.642269 3.34993 0.347412 4.34173 0.347412C5.33352 0.347412 6.13767 0.642269 6.75419 1.23198C7.37071 1.8217 7.67897 2.65936 7.67897 3.74497C7.67897 4.83058 7.37071 5.66824 6.75419 6.25796C6.13767 6.84767 5.33352 7.14253 4.34173 7.14253C3.34993 7.14253 2.53908 6.84767 1.90915 6.25796C1.29263 5.66824 0.984375 4.83058 0.984375 3.74497ZM14.6349 0.568554L6.89492 14.7217H3.57778L11.3178 0.568554H14.6349ZM4.32162 2.3578C3.6783 2.3578 3.35663 2.82019 3.35663 3.74497C3.35663 4.65635 3.6783 5.11204 4.32162 5.11204C4.62988 5.11204 4.87113 4.99811 5.04536 4.77027C5.2196 4.54243 5.30671 4.20066 5.30671 3.74497C5.30671 2.82019 4.97835 2.3578 4.32162 2.3578ZM10.5538 11.5453C10.5538 10.4597 10.8621 9.62201 11.4786 9.0323C12.0951 8.44258 12.8993 8.14772 13.8911 8.14772C14.8829 8.14772 15.687 8.44258 16.3035 9.0323C16.9201 9.62201 17.2283 10.4597 17.2283 11.5453C17.2283 12.6309 16.9201 13.4686 16.3035 14.0583C15.687 14.648 14.8829 14.9428 13.8911 14.9428C12.8993 14.9428 12.0951 14.648 11.4786 14.0583C10.8621 13.4686 10.5538 12.6309 10.5538 11.5453ZM13.871 10.1581C13.5627 10.1581 13.3215 10.272 13.1472 10.4999C12.9864 10.7277 12.906 11.0762 12.906 11.5453C12.906 12.4567 13.2276 12.9123 13.871 12.9123C14.1792 12.9123 14.4205 12.7984 14.5947 12.5706C14.7689 12.3427 14.8561 12.001 14.8561 11.5453C14.8561 11.0896 14.7689 10.7478 14.5947 10.52C14.4205 10.2787 14.1792 10.1581 13.871 10.1581Z"
                            fill="#929292"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['modelValue', 'title', 'disabled'],

    methods: {
        sanitize() {
                 // Remove not number except the period and negative. 
                 let v = (this.modelValue || '').toString().replace(/(?!^-)[^0-9.]/g, "")
                 // Convert to valid number, remove two or more commas and allow negative number
                  v = (v).replace(/(?!^-).*?(([0-9]*\.)?[0-9]+).*/g, "$1")
                 // Prevent from converting the empty string to 0, set null as default
                  v = v ? Number(v) : null;
                this.$emit('update:modelValue', v);
        }
    },

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        },
    },
};
</script>

<style scoped lang="scss">
$inputPlaceHolder: rgba(57, 57, 57, 0.5);
$borderColor: #878787;
$textColor: #3f3f3f;
$inputFocus: rgb(4, 127, 255) ;
$textRed: #dd2c01;
$inputBg: rgba(241, 241, 241, 1);
$inputBgFocus: rgba(249, 249, 249, 1);

section {
    position: relative;
    text-align: left;
    color: rgba(62, 62, 62, 1);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    label {
        line-height: 18px;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        padding-bottom: 3.4px;
    }
    .container {
        height: 41.18px;
        position: relative;
        display: flex;
        font-size: 16px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        background: $inputBg;

        .icon {
            width: 33px !important;
            border-radius: 4px;
            background: #e1e1e1;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            border: 2px solid transparent;
            svg {
                position: absolute;
                right: 5.5px;
            }
        }
        input {
            background: transparent;
            border: none;
            width: 100%;
            flex: 1;
            font-size: 14px;
            padding-left: calc(33px + 13.4px);
            padding-right: 13.4px;
            z-index: 2;
        }
    }

    input + div {
        width: 100%;
        height: 100%;
        position: absolute;
        border: 1px solid $borderColor;
        border-radius: 4px;
        pointer-events: none;
        background: transparent;
    }

    input.error + div {
        border: 2px solid $textRed;
    }

    input:focus + div {
        border: 2px solid $inputFocus !important;
        background: $inputBgFocus;
    }
    input:focus + div .icon {
        width: 32px !important;
        border-radius: 3px;
    }
}

 section.disabled {
                * {
                    pointer-events: none;
                }
                .container {
                    background: rgba(232, 232, 232, 1);
                }
                input + div  {
                    border-color: transparent !important;
                }
    }
</style>
