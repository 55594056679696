<template>
    <section :class="disabled && 'disabled'">
        <label>{{ title }}</label>
        <div class="flex relative container flex-row curser-pointer" @blur="show = false">
            <div class="input" :class="show ? 'bg-unfocus' : 'bg-focus'" @click="show = true">
                <input :value="value" :required="!!required" @focus="show = true" @keydown.tab="show = false" />
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.34355 6.15356L0.972335 0.403279L9.71476 0.403278L5.34355 6.15356Z" fill="#C4C4C4" />
                </svg>
            </div>
            <div :class="show && 'show'"></div>
            <aside class="options" ref="options" v-if="show" v-click-outside="hideOptions">
                <template v-for="(option, i) in options">
                    <template v-if="option === ''">
                        <a href="#" class="option"  @click.prevent="select(option)">
                            <div>&nbsp;</div>
                        </a>
                    </template>
                    <template v-else>
                        <a
                            href="#"
                            :class="[option === value && 'active', `option-${i}`]"
                            :tabindex="i"
                            :option-key="`option-${i}`"
                            class="option"
                            @click.prevent="select(option)"
                        >
                            <div>
                                {{ option }}
                            </div>
                        </a>
                    </template>
                </template>
            </aside>
        </div>
    </section>
</template>

<script>
export default {
    props: ['modelValue', 'title', 'options', 'disabled', 'required'],

    data() {
        return {
            error: false,
            show: false,
            selected: null,
        };
    },

    methods: {

        hideOptions() {
            this.show = false;
        },

        select(v) {
            this.show = false;
            this.value = v;
        },
    },

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            },
        },
    },
};
</script>

<style scoped lang="scss">
$inputPlaceHolder: rgba(57, 57, 57, 0.5);
$borderColor: #878787;
$textColor: #3f3f3f;
$inputFocus: rgb(4, 127, 255);
$textRed: #dd2c01;
$inputBg: rgba(241, 241, 241, 1);
$inputBgFocus: rgba(249, 249, 249, 1);

section {
    position: relative;
    text-align: left;
    color: rgba(62, 62, 62, 1);
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    width: 100%;

    label {
        line-height: 18px;
        font-weight: 700;
       font-size: 14px;
        white-space: nowrap;
        padding-bottom: 3.4px;
    }

    .container {
        width: 100%;
        height: 41.18px;
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 4px;
        .input + div {
            width: 100%;
            height: 100%;
            position: absolute;
            border: 1px solid $borderColor;
            border-radius: 4px;
            pointer-events: none;
        }
        .input {
            display: flex;
            align-items: center;
            background: transparent;
            width: 100%;
            border: none;
            text-align: left;
            padding-left: 13.4px;
            padding-right: 33px;
            font-weight: 500;
            user-select: none;
            border-radius: 4px;
            cursor: pointer;
        }
        input {
            font-size: 14px;
            height: 41.18px;
            padding: 0;
            pointer-events: none;
            background: transparent;
            resize: none;
            outline: none;
            caret-color: transparent;
            border: none;
        }
        svg {
            position: absolute;
            right: 13.4px;
            cursor: pointer;
        }
        .show {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }

    .bg-focus {
        background: $inputBg !important;
    }
    .bg-unfocus {
        background: $inputBgFocus !important;
    }

    .options {
        z-index: 99999;
        position: absolute;
        top: 41.18px;
        max-height: 177px;
        overflow-y: auto;
        border-top: none !important;

        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom: 0.5px solid #8a898a !important;

        border-left: 0.5px solid #8a898a !important;
        border-right: 0.5px solid #8a898a !important;
        width: 100%;

        .option {
            padding-top: 5px;
            padding-bottom: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 13.42px;
            background: white;
            border-top: 0.5px dashed #cecece;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                background: #047fff;
                color: white;
            }
            &:active {
                background: #0070e4;
                color: white;
            }
        }
        .option:first-child {
            border-top: none;
        }
        .option:last-child {
            padding-bottom: 10px;
        }
        .option.active {
            background: #0070e4;
            color: white;
        }
    }
}

section.disabled {
    * {
        pointer-events: none;
    }
    .input {
        background: rgba(232, 232, 232, 1) !important;
    }
    .input + div {
        border-color: transparent !important;
    }
}

*:focus {
    outline: none;
}
</style>