<template>
    <section :class="!spread && 'hide'">
        <div class="w-full sidebar-backbutton">
            <svg width="41" height="44" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg" @click="spread = !spread">
                <path
                    d="M0.0627441 4.58838C0.0627441 2.37924 1.85361 0.588379 4.06274 0.588379L40.4417 0.588379L40.4417 43.8189L4.06274 43.8189C1.8536 43.8189 0.0627441 42.028 0.0627441 39.8189L0.0627441 4.58838Z"
                    fill="#3E9AFB"
                />
                <path d="M24.5525 27.7954L18.9608 22.2037L24.5525 16.612L23.0481 15.1076L15.952 22.2037L23.0481 29.2998L24.5525 27.7954Z" fill="#FEFEFE" />
            </svg>
        </div>
        <ul class="flex flex-col">
            <a href="#" @click.prevent="moveTo('customers')" :title="!spread ? 'Customers' : ''">
                <li :class="routeName === 'customers' && 'active'">
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_617_800)">
                            <path
                                d="M3.75439 23.0252C3.75439 22.9811 3.75439 22.937 3.75439 22.8928C3.76341 22.0896 3.76341 21.2953 3.78143 20.4921C3.79946 19.8566 3.85354 19.2211 4.00677 18.6033C4.25914 17.5618 4.88106 16.8292 5.89055 16.4056C6.67471 16.079 7.45887 15.7436 8.23402 15.3994C8.67567 15.1964 9.0993 14.9493 9.53194 14.7286C9.60404 14.6933 9.67615 14.658 9.7753 14.605C10.4333 16.6351 11.0912 18.6563 11.7492 20.6775C11.7582 20.6775 11.7763 20.6775 11.7853 20.6775C11.8033 20.6422 11.8213 20.598 11.8393 20.5627C12.0737 19.9272 12.308 19.2917 12.5424 18.6474C12.5604 18.6033 12.5424 18.5327 12.5153 18.4974C12.281 18.1532 12.0557 17.8089 11.9295 17.4118C11.8754 17.2352 11.8394 17.0411 11.8303 16.8557C11.8123 16.6351 11.9115 16.4497 12.0917 16.3085C12.308 16.132 12.5514 16.0172 12.8488 16.0261C12.9931 16.0349 13.1463 16.0084 13.2905 16.0349C13.4527 16.0525 13.615 16.079 13.7592 16.1496C14.2189 16.3615 14.3631 16.6615 14.2639 17.1558C14.1828 17.5353 14.0025 17.8796 13.7952 18.2061C13.7321 18.312 13.651 18.418 13.5879 18.5239C13.5609 18.568 13.5519 18.6298 13.5699 18.6651C13.8043 19.3006 14.0386 19.9361 14.2729 20.5804C14.291 20.6157 14.309 20.651 14.336 20.7216C14.3631 20.651 14.3811 20.6157 14.3901 20.5715C14.9489 18.8593 15.5078 17.147 16.0666 15.4259C16.1387 15.2052 16.2108 14.9846 16.2829 14.7639C16.328 14.6315 16.346 14.6227 16.4722 14.6933C16.6254 14.7727 16.7787 14.8698 16.9409 14.9493C17.9053 15.4612 18.9058 15.9025 19.9243 16.2997C20.3209 16.4585 20.7085 16.6262 21.051 16.891C21.5828 17.3059 21.8892 17.8619 22.0695 18.4886C22.2497 19.124 22.3038 19.7684 22.3219 20.4215C22.3399 21.26 22.3579 22.0985 22.3759 22.937C22.3759 22.9634 22.3759 22.9987 22.3759 23.034C16.1657 23.0252 9.97359 23.0252 3.75439 23.0252Z"
                                fill="white"
                            />
                            <path
                                d="M8.60362 7.86187C8.55856 7.59708 8.49546 7.34113 8.46842 7.09399C8.32421 5.70828 8.78389 4.54323 9.77536 3.56352C10.3342 3.00747 10.9651 2.54851 11.7132 2.25725C13.2184 1.66589 15.0031 2.05424 16.0396 3.06925C16.301 3.33404 16.5534 3.58117 16.8959 3.7577C17.3916 4.00483 17.626 4.47262 17.7612 4.99336C17.9054 5.59354 17.9234 6.21137 17.8603 6.82921C17.8152 7.22639 17.7431 7.63239 17.68 8.02957C17.671 8.109 17.68 8.15313 17.7522 8.19727C17.9504 8.32083 17.9865 8.53266 17.9775 8.73566C17.9595 9.00927 17.9234 9.29171 17.8693 9.5565C17.7792 9.98015 17.644 10.395 17.4277 10.7833C17.3646 10.8893 17.2925 10.9863 17.2114 11.0746C17.1392 11.1629 17.0311 11.207 16.9049 11.1805C16.8058 11.1629 16.7877 11.2246 16.7697 11.2953C16.6706 11.6395 16.5804 11.9837 16.4632 12.3191C16.2289 12.9634 15.8413 13.5018 15.3186 13.9431C14.8048 14.3668 14.1919 14.6139 13.5339 14.7022C11.7222 14.9581 10.0007 13.899 9.37877 12.169C9.27061 11.8513 9.18949 11.5247 9.10837 11.1982C9.07232 11.0658 9.02725 10.9687 8.91008 10.8716C8.75685 10.748 8.63968 10.5715 8.54954 10.395C8.34224 9.99781 8.22506 9.56532 8.15296 9.12401C8.10789 8.87688 8.09888 8.62092 8.09888 8.36496C8.09888 8.11783 8.23408 7.95013 8.48645 7.88835C8.5225 7.89718 8.56757 7.8707 8.60362 7.86187Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_617_800">
                                <rect width="18.6215" height="21.0504" fill="white" transform="translate(3.75439 1.97479)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span class="title">Customers</span>
                </li>
            </a>
            <a href="#" @click.prevent="moveTo('policies')" :title="!spread ? 'Policies' : ''">
                <li :class="routeName === 'policies' && 'active'">
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_617_806)">
                            <path
                                d="M13.2273 1.64819C15.0288 3.36575 16.7772 5.13535 18.6582 6.77483C19.3205 7.34735 19.4794 7.91986 19.4264 8.67455C19.3734 9.48127 19.4264 10.314 19.4264 11.2249C19.029 10.9906 18.6582 10.8345 18.3668 10.6003C18.2608 10.5222 18.2343 10.3401 18.2343 10.1839C18.2078 9.61139 18.2343 9.0649 18.2343 8.44033C18.0224 8.44033 17.8634 8.44033 17.678 8.44033C16.2209 8.44033 14.7639 8.44033 13.3068 8.44033C12.5915 8.44033 12.4061 8.25817 12.4061 7.52951C12.4061 6.15026 12.4061 4.79704 12.4061 3.41779C12.4061 3.23563 12.4061 3.07949 12.4061 2.8713C9.46552 2.8713 6.55143 2.8713 3.61084 2.8713C3.61084 9.01285 3.61084 15.1544 3.61084 21.348C3.79628 21.348 3.95523 21.348 4.11418 21.348C6.97529 21.348 9.8364 21.348 12.724 21.348C13.1214 21.348 13.4128 21.4261 13.5982 21.7904C13.7307 22.0246 13.9426 22.2328 14.1546 22.5191C13.9426 22.5191 13.7837 22.5451 13.6512 22.5451C10.3662 22.5451 7.08126 22.5451 3.79628 22.5451C2.68363 22.5711 2.31274 22.2328 2.31274 21.1658C2.31274 15.1284 2.31274 9.09092 2.31274 3.05346C2.31274 2.37685 2.52468 1.93445 3.13399 1.64819C6.49844 1.64819 9.8629 1.64819 13.2273 1.64819ZM17.3336 7.1912C16.062 6.02015 14.8963 4.95318 13.6247 3.78212C13.6247 4.9792 13.6247 6.07219 13.6247 7.1912C14.8433 7.1912 16.0355 7.1912 17.3336 7.1912Z"
                                fill="white"
                            />
                            <path
                                d="M18.1283 23.3518C16.2739 22.6231 14.8698 21.426 14.0221 19.6044C13.4658 18.3813 13.2009 17.0541 13.1214 15.7269C13.0949 15.1544 13.1214 14.5558 13.1214 13.9573C13.5982 13.9313 14.1016 13.9052 14.5784 13.8532C15.7971 13.7231 16.8832 13.2547 17.8899 12.552C18.1283 12.3959 18.2608 12.4219 18.4727 12.552C19.6384 13.2286 20.8305 13.8012 22.2081 13.9573C22.526 13.9833 22.8439 14.0093 23.1618 14.0093C23.5327 17.1322 22.6849 21.6603 18.2343 23.3518C18.1813 23.3518 18.1548 23.3518 18.1283 23.3518ZM17.3336 20.255C18.5522 18.5374 19.7708 16.8199 20.963 15.1544C20.2477 14.9982 19.6119 15.1804 19.1085 15.831C18.7376 16.3254 18.3138 16.7678 17.9164 17.2363C17.731 17.4705 17.5455 17.7307 17.3336 18.017C16.8567 17.4444 16.3004 17.2102 15.6116 17.3143C15.5057 17.3404 15.3732 17.3924 15.3202 17.4705C15.2937 17.5485 15.3467 17.7307 15.3997 17.7828C16.3269 18.4594 16.9097 19.3442 17.3336 20.255Z"
                                fill="white"
                            />
                            <path
                                d="M9.91576 11.5631C11.1079 11.5631 12.3 11.5631 13.4657 11.5631C13.7836 11.5631 14.0485 11.6152 14.1015 11.9795C14.1544 12.3699 13.863 12.4479 13.5716 12.474C13.4657 12.5 13.3862 12.5 13.2802 12.5C10.9754 12.5 8.67065 12.5 6.36587 12.5C6.02148 12.5 5.73007 12.4219 5.75656 12.0055C5.75656 11.5892 6.04797 11.5371 6.39236 11.5371C7.53151 11.5631 8.72364 11.5631 9.91576 11.5631Z"
                                fill="white"
                            />
                            <path
                                d="M11.9557 18.3813C11.1874 18.3813 10.4457 18.3813 9.73039 18.3813C8.56476 18.3813 7.42561 18.3813 6.25997 18.3813C5.94207 18.3813 5.73014 18.3032 5.70365 17.9389C5.67716 17.6006 5.88909 17.4445 6.20699 17.4445C8.03492 17.4445 9.86285 17.4445 11.7173 17.4445C11.7968 17.7567 11.8762 18.043 11.9557 18.3813Z"
                                fill="white"
                            />
                            <path
                                d="M11.3995 14.4778C11.3995 14.8161 11.3995 15.1023 11.3995 15.4146C10.8431 15.4146 10.3133 15.4146 9.78346 15.4146C8.59133 15.4146 7.4257 15.4146 6.23357 15.4146C5.94216 15.4146 5.73022 15.2845 5.73022 14.9722C5.73022 14.6599 5.88918 14.4778 6.23357 14.4778C7.92904 14.4778 9.62451 14.4778 11.3995 14.4778Z"
                                fill="white"
                            />
                            <path
                                d="M8.29981 9.50732C7.63751 9.50732 6.94873 9.50732 6.28643 9.50732C5.96853 9.50732 5.70361 9.42925 5.70361 9.0389C5.70361 8.70059 5.94204 8.5965 6.25994 8.5965C7.61102 8.5965 8.98859 8.5965 10.3397 8.5965C10.6576 8.5965 10.896 8.70059 10.896 9.0389C10.896 9.40323 10.6576 9.50732 10.3132 9.50732C9.65089 9.50732 8.9621 9.50732 8.29981 9.50732Z"
                                fill="white"
                            />
                            <path
                                d="M7.31968 6.7488C6.94879 6.7488 6.57791 6.7488 6.20703 6.7488C5.88912 6.7488 5.70368 6.61868 5.70368 6.3064C5.67719 5.99412 5.86263 5.83797 6.15404 5.81195C6.9223 5.81195 7.69056 5.81195 8.45882 5.81195C8.77673 5.81195 8.96217 6.02014 8.90918 6.28037C8.8562 6.46254 8.64427 6.67073 8.45882 6.69675C8.08794 6.80084 7.71706 6.7488 7.31968 6.7488Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_617_806">
                                <rect width="20.9285" height="21.7036" fill="white" transform="translate(2.31274 1.64819)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span class="title"> Policies</span>
                </li>
            </a>

            <a href="#" @click.prevent="moveTo('renewals')" :title="!spread ? 'Renewals' : ''">
                <li :class="routeName === 'renewals' && 'active'">
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_617_823)">
                            <path
                                d="M21.821 6.6857C22.356 6.11662 22.8619 5.56767 23.4116 4.98348C23.4116 6.79146 23.4116 8.53398 23.4116 10.2966C21.714 10.2966 20.0163 10.2966 18.3479 10.2966C18.9802 9.64194 19.632 8.97213 20.2839 8.29728C19.9093 7.60732 19.5056 7.02313 19.0045 6.52455C16.6599 4.17265 13.8776 3.33665 10.7401 4.28848C7.53937 5.25543 5.56446 7.53682 4.90291 10.906C4.0322 15.3479 6.89728 19.7999 11.1876 20.8172C15.619 21.8698 19.9774 19.1251 21.0913 14.5623C21.2859 13.7716 21.8064 13.4997 22.3609 13.5702C22.9738 13.6507 23.3873 14.2752 23.2024 14.9299C22.9933 15.6602 22.7938 16.4005 22.4728 17.0804C20.853 20.4697 18.2165 22.5194 14.6023 23.0986C9.15918 23.9749 3.99329 20.1776 2.928 14.5875C1.80921 8.77068 5.43312 3.16038 11.0709 1.97688C15.1082 1.1308 19.3402 2.91361 21.6556 6.43893C21.7091 6.51447 21.7577 6.59002 21.821 6.6857Z"
                                fill="white"
                            />
                            <path
                                d="M17.1901 16.7429C14.432 16.7429 11.6886 16.7429 8.8916 16.7429C8.92565 16.5213 8.94024 16.2947 8.99375 16.0882C9.15914 15.4688 9.5191 14.9803 9.9958 14.5824C11.674 13.1824 14.4856 13.1975 16.1443 14.6177C16.6696 15.0709 17.0393 15.6299 17.1658 16.335C17.1804 16.4105 17.1852 16.4861 17.1901 16.5566C17.1901 16.612 17.1901 16.6674 17.1901 16.7429Z"
                                fill="white"
                            />
                            <path
                                d="M13.0603 12.4672C11.9123 12.4722 10.9637 11.4952 10.9735 10.3168C10.9783 9.1383 11.9074 8.18646 13.0554 8.18646C14.1985 8.18646 15.1276 9.14334 15.1324 10.3218C15.1276 11.4952 14.1936 12.4672 13.0603 12.4672Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_617_823">
                                <rect width="20.6928" height="21.4592" fill="white" transform="translate(2.71875 1.77039)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span class="title"> Renewals</span>
                </li>
            </a>

            <a href="#" @click.prevent="moveTo('performance')" :title="!spread ? 'Performance' : ''">
                <li :class="routeName === 'performance' && 'active'">
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_617_841)">
                            <path
                                d="M14.0636 15.3418C12.1892 13.5715 10.3149 11.8323 8.44047 10.062C8.40975 10.093 8.34829 10.1552 8.31756 10.1862C7.02701 11.6459 5.70572 13.1056 4.41516 14.5653C4.04643 14.9691 3.52406 15.0312 3.12461 14.7517C2.66369 14.41 2.60224 13.7578 3.0017 13.323C3.58552 12.6398 4.20007 11.9876 4.78389 11.3043C5.92081 10.0309 7.08846 8.72651 8.22538 7.45316C8.25611 7.4221 8.28684 7.35998 8.34829 7.32893C10.2227 9.0992 12.0971 10.8384 13.9714 12.6087C16.0916 10.2173 18.2118 7.8569 20.3628 5.43442C20.2706 5.43442 20.2399 5.43442 20.1784 5.43442C19.1029 5.43442 17.9967 5.43442 16.9213 5.43442C16.4604 5.43442 16.0916 5.1549 15.9687 4.72009C15.7536 4.13 16.1531 3.53991 16.7369 3.50885C16.7984 3.50885 16.8291 3.50885 16.8906 3.50885C18.7342 3.50885 20.5471 3.50885 22.3908 3.50885C22.7595 3.50885 23.0975 3.63308 23.2819 3.97471C23.3741 4.13 23.4048 4.31635 23.4355 4.47163C23.4355 6.58354 23.4355 8.69546 23.4355 10.7763C23.4355 11.3353 23.0053 11.7391 22.483 11.7391C21.9606 11.7391 21.5304 11.3353 21.5304 10.7763C21.5304 9.56507 21.5304 8.38488 21.5304 7.17364C21.5304 7.11152 21.5304 7.08047 21.5304 6.95624C19.0108 9.78247 16.5526 12.5466 14.0636 15.3418Z"
                                fill="white"
                            />
                            <path
                                d="M13.0498 21.4911C9.94628 21.4911 6.87352 21.4911 3.77004 21.4911C3.49349 21.4911 3.24767 21.429 3.0633 21.2737C2.75603 21.0253 2.66384 20.6215 2.75603 20.2488C2.87894 19.8761 3.21694 19.5966 3.6164 19.5966C3.64713 19.5966 3.70858 19.5966 3.73931 19.5966C9.94628 19.5966 16.1225 19.5966 22.3295 19.5966C22.7597 19.5966 23.0977 19.7519 23.282 20.1557C23.5586 20.7768 23.1284 21.4601 22.4831 21.4911C22.4217 21.4911 22.3295 21.4911 22.268 21.4911C19.226 21.4911 16.1532 21.4911 13.0498 21.4911Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_617_841">
                                <rect width="20.6797" height="17.9823" fill="white" transform="translate(2.72534 3.50885)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span class="title"> Performance</span>
                </li>
            </a>

            <a href="#" v-if="activeRoute" @click.prevent="showAddModel()" class="add-modal-container" :title="!spread ? 'New ' + activeRoute.text : ''">
                <li>
                    <span class="flex add-modal-wrapper">
                        <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="23.2056" cy="23.1261" r="22.4318" fill="white" />
                            <path d="M23.2057 13.1066V34.3667" stroke="#047FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.5756 23.7369H33.8357" stroke="#047FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span class="title"> New {{ activeRoute.text }}</span>
                    </span>
                </li>
            </a>
            <span v-else></span>
        </ul>
    </section>
</template>

<script>
export default {
    data() {
        return {
            addButton: [
                {
                    route: 'customers',
                    text: 'Customer',
                    commit: 'customers/showAdd',
                },
                {
                    route: 'policies',
                    text: 'Policy',
                    commit: 'policies/showAdd',
                },
            ],
        };
    },

    computed: {
        routeName() {
            return this.$route.name;
        },

        activeRoute() {
            if (this.routeName === 'customers') {
                return this.addButton.find((item) => item.route === this.routeName);
            }
            return false;
        },

        spread: {
            get() {
               return this.$store.getters['getSpreadSidebar']; 
            },

            set(v) {
                this.$store.commit('toggleSidebar', v)
            }
        }
    },

    methods: {
        showAddModel() {
            this.$store.commit(this.activeRoute.commit);
        },

        moveTo(uri) {
            this.$router.push(uri)
        }
    },
};
</script>

<style scoped lang="scss">
section {
    z-index: 999;
    @apply bg-blue-500;
    transition: all 0.2s;
    width: 260px;
    .sidebar-backbutton {
        padding-top: 21.83px;
        padding-bottom: 23.16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
            transition: all 0.3s;
            border: 1px solid transparent;
            border-radius: 4px;
            &:active {
                border-color: #0479f2;
            }
            cursor: pointer;
            path {
                &:hover,
                &:active {
                    fill: #005cbc;
                }
            }
        }
    }

    ul {
        // position: relative;
        height: 100%;
        overflow: hidden;
        li.active {
            background: theme('colors.blue.650');
        }
        li {
            user-select: none;
            width: 260px;
            height: 67.98px;
            @apply flex items-center hover:bg-blue-670 active:bg-blue-700;
            &:active {
                border-color: #7abbff;
            }
            font-size: 20px;
            padding: 13.89px 0 13.89px 30px;
            span {
                color: white;
                padding-left: 13.89px;
                line-height: 27px !important;
            }
            .active {
                @apply bg-blue-650;
                white-space: nowrap;
            }
            svg {
                height: 24px !important;
                width: 24px !important;
            }
        }
    }

    .add-modal-container {
        li {
            font-size: 18px;
            position: absolute;
            bottom: 53.11px;
            padding-left: 20px !important;
            &:hover {
                background: transparent;
            }
        }

        .add-modal-wrapper {
            padding-right: 14.78px;
            padding-left: 10.92px;
            height: 62.95px;
            justify-content: center;
            align-items: center;
            border-radius: 31.4756px;
            &:hover {
                background: rgba(29, 140, 255, 1);
                svg {
                    // border: 5px solid  rgba(255, 255, 255, .1);
                }
            }
            &:active {
                background: rgba(67, 159, 255, 1);
                svg {
                    // border: 5px solid  rgba(255, 255, 255, .2);
                }
            }
        }
        svg {
            width: 43.38px !important;
            height: 43.38px !important;
            border: 5px solid transparent;
            border-radius: 50%;
        }
        span.title {
            padding-left: 3.75px !important;
            white-space: nowrap;
        }
    }
}

.hide {
    span.title {
        display: none;
    }
    width: 86px;
    overflow: hidden;
    position: relative;
    a {
        width: 86px;
    }
    ul li {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 67.98px;
        svg {
            margin-right: auto;
        }
    }
    .sidebar-backbutton {
        justify-content: center;
        padding-right: 0;
        svg {
            transform: rotate(180deg);
        }
    }

    .add-modal-container {
        li {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .add-modal-wrapper {
            span {
                padding-left: 0px !important;
                white-space: nowrap;
            }
            &:hover {
                background: transparent !important;
                svg {
                  border: 5px solid rgba(255, 255, 255, 0.1) !important;
                }
            }
            &:active svg {
                border: 5px solid rgba(255, 255, 255, 0.2) !important;
            } 
        }
    }
}
</style>
