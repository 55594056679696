<template>
    <section :class="disabled && 'disabled'">
        <label>{{ title }}</label>
        <div class="container flex flex-row items-stretch">
            <input v-model.trim="value" @blur="format()" :required="required" ref="input" :disabled="disabled"/>
            <div>
                <div class="icon">
                    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.4962 11.4266C10.4962 12.1081 10.3165 12.74 9.95717 13.3224C9.61022 13.8924 9.0898 14.3633 8.39591 14.735C7.71441 15.0943 6.9028 15.2926 5.96109 15.3298V16.8353H4.77156V15.3112C3.45812 15.1997 2.3987 14.8093 1.59328 14.1402C0.787874 13.4587 0.360386 12.5356 0.310823 11.3709H3.69355C3.76789 12.1143 4.12723 12.5914 4.77156 12.802V9.86535C3.80507 9.61753 3.03063 9.37591 2.44826 9.14048C1.87828 8.90505 1.37644 8.52713 0.94276 8.00671C0.509078 7.48629 0.292236 6.77381 0.292236 5.86927C0.292236 4.7417 0.707333 3.83716 1.53753 3.15566C2.38011 2.47416 3.45812 2.09004 4.77156 2.0033V0.497803H5.96109V2.0033C7.26214 2.10243 8.29059 2.48035 9.04643 3.13707C9.80228 3.79379 10.2236 4.70453 10.3103 5.86927H6.909C6.83465 5.20016 6.51868 4.76648 5.96109 4.56823V7.44912C6.98954 7.73411 7.78256 7.98812 8.34015 8.21116C8.89774 8.4342 9.39338 8.80593 9.82706 9.32635C10.2731 9.83437 10.4962 10.5345 10.4962 11.4266ZM3.65638 5.72058C3.65638 6.03036 3.74931 6.29057 3.93517 6.50121C4.13343 6.71186 4.41222 6.89772 4.77156 7.0588V4.47529C4.42461 4.53725 4.15201 4.67355 3.95376 4.88419C3.7555 5.08245 3.65638 5.36125 3.65638 5.72058ZM5.96109 12.8578C6.33282 12.7958 6.624 12.6471 6.83465 12.4117C7.05769 12.1763 7.16921 11.8851 7.16921 11.5381C7.16921 11.216 7.06388 10.9558 6.85324 10.7575C6.65498 10.5469 6.3576 10.3672 5.96109 10.2185V12.8578Z"
                            fill="#929292"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['modelValue', 'title','disabled', 'required'],
    methods: {
        format() {
            let v = this['value'];
            if(!v) {
                return
            }
            v = v.toString().replace(/[^0-9.]/g, '');
            this['value'] = this.formatDollar(v);
        },

        formatDollar(num) {
            if (!num) {
                return ''
            }
            num = parseFloat(num)
            return num.toLocaleString('en-US', { minimumFractionDigits: 2 })
        },
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },

            set(v) {
                this.$emit('update:modelValue', v);
            },
        },
    },
};
</script>

<style scoped lang="scss">
$inputPlaceHolder: rgba(57, 57, 57, 0.5);
$borderColor: #878787;
$textColor: #3f3f3f;
$inputFocus: rgb(4, 127, 255) ;
$textRed: #dd2c01;
$inputBg: rgba(241, 241, 241, 1);
$inputBgFocus: rgba(249, 249, 249, 1);

section {
    position: relative;
    text-align: left;
    color: rgba(62, 62, 62, 1);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
        line-height: 18px;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        padding-bottom: 3.4px;
    }
    .container {
        height: 41.18px;
        position: relative;
        display: flex;
        font-size: 16px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        background: $inputBg;

        .icon {
            width: 33px !important;
            border-radius: 4px;
            background: #e1e1e1;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            border: 2px solid transparent;
            svg {
                position: absolute;
                right: 9px;
            }
        }
        input {
            background: transparent;
            border: none;
            width: 100%;
            flex: 1;
            font-size: 14px;
            padding-left: calc(33px + 13.4px);
            padding-right: 13.4px;
            z-index: 2;
        }
    }

    input + div {
        width: 100%;
        height: 100%;
        position: absolute;
        border: 1px solid $borderColor;
        border-radius: 4px;
        pointer-events: none;
        background: transparent;
    }

    input.error + div {
        border: 2px solid $textRed;
    }

    input:focus + div {
        border: 2px solid $inputFocus !important;
        background: $inputBgFocus;
    }
    input:focus + div .icon {
        width: 32px !important;
        border-radius: 3px;
    }
}

 section.disabled {
                * {
                    pointer-events: none;
                }
                .container {
                    background: rgba(232, 232, 232, 1);
                }
                input + div  {
                    border-color: transparent !important;
                }
    }
</style>
