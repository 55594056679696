<template>
    <transition name="slide-in-left">
        <section class="text-white mt-1 rounded-xl absolute z-10 bg-white shadow-lg right-0 top-16 p-7 w-56" v-click-outside="clickOutside">
            <ul class="list-none overflow-hidden select-none">
                <li>
                    <svg style="height: 12px; width: 12px" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.4812 10.7062V5.15898C13.4809 4.91578 13.4167 4.67693 13.295 4.46638C13.1733 4.25583 12.9984 4.08099 12.7878 3.95939L7.934 1.1858C7.72318 1.06408 7.48403 1 7.2406 1C6.99716 1 6.75802 1.06408 6.5472 1.1858L1.6934 3.95939C1.48279 4.08099 1.30786 4.25583 1.18615 4.46638C1.06445 4.67693 1.00025 4.91578 1 5.15898V10.7062C1.00025 10.9494 1.06445 11.1882 1.18615 11.3988C1.30786 11.6093 1.48279 11.7842 1.6934 11.9058L6.5472 14.6794C6.75802 14.8011 6.99716 14.8651 7.2406 14.8651C7.48403 14.8651 7.72318 14.8011 7.934 14.6794L12.7878 11.9058C12.9984 11.7842 13.1733 11.6093 13.295 11.3988C13.4167 11.1882 13.4809 10.9494 13.4812 10.7062Z"
                            stroke="#2245A2"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path d="M1.18723 4.43784L7.2406 7.9395L13.294 4.43784" stroke="#2245A2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.2406 14.9221V7.93259" stroke="#2245A2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h5 class="text-12px font-normal cursor-pointer text-gray-600">Dashboard</h5>
                </li>
                <li>
                    <svg style="height: 12px; width: 12px" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.91666 12V7.72223" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2.91666 5.27777V1" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.02774 12V6.5" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.02774 4.05555V1" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.1388 12V8.94443" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.1388 6.5V1" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1 7.72223H4.83333" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.11108 4.05557H9.94441" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.2222 8.94443H15.0555" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <h5 class="text-12px font-normal cursor-pointer text-gray-600">Account Settings</h5>
                </li>

                <li class="py-5">
                    <div class="border w-full border-t-0 border-gray-300 border-r-0 border-l-0"></div>
                </li>

                <li>
                    <svg style="height: 12px; width: 12px" @click="logout" class="cursor-pointer" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H5"
                            stroke="#15399B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path d="M9.66669 10.3333L13 6.99999L9.66669 3.66666" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.0001 7H5.00009" stroke="#15399B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h5 class="text-12px font-normal cursor-pointer text-gray-600" @click="logout">Logout</h5>
                </li>
            </ul>
        </section>
    </transition>
</template>

<script>
export default {
    props: {
        logout: Function,
        hide: Function,
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.isLoaded = true;
        }, 300);
    },
    methods: {
        clickOutside() {
            if (this.isLoaded) {
                this.hide();
            }
        },
    },
};
</script>

<style scoped>
section {
    z-index: 9999;
    height: auto;
}
/* Slide-in-left animation*/
.slide-in-left-enter-active,
.slide-in-left-leave-active {
    transition: all 0.5s;
    transform: translateX(0);
}
.slide-in-left-enter,
.slide-in-left-leave-to {
    opacity: 0;
    transform: translateX(-20px);
}
ul {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
ul li {
    display: flex;
    align-items: center;
    column-gap: 0.45rem;
}
ul li h5 {
    white-space: nowrap;
}
</style>
