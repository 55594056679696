<template>
	<section>
		<span v-html="computedText"></span>
	</section>
</template>

<script>
	export default {
		props: ['pagination', 'singular', 'plural'],

		computed: {
			computedText() {
				const total = (this.pagination && ('total' in this.pagination)) ? this.pagination.total : 0;

				if(total === 0) {
					return
				}

				if(total === 1) {
					return `<b>1</b>&nbsp; ${this.singular}`
				}

				return `<b>${total}</b>&nbsp; ${this.plural}`

			}
		}

	}
</script>

<style scoped>
	section {
		font-weight: 400;
		font-size: 14px;
		color: #313962;
		white-space: nowrap;
	}
</style>