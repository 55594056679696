<template>
    <section :class="disabled && 'disabled'">
        <label>{{ title }}</label>
        <div class="flex container flex-row">
            <input type="text" :class="error && 'error'" ref="textinput" :value="formattedDate" @keyup="setDateText" @blur="sanitize" placeholder="M / D / YY" :required="required" />
            <div></div>
            <a href="#" @click.prevent="showDate">
              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" >

                <path
                    d="M14.7909 2.18072H14.0027V0.421631H12.4265V2.18072H4.54498V0.421631H2.96869V2.18072H2.18054C1.31358 2.18072 0.604248 2.97231 0.604248 3.9398V18.0125C0.604248 18.98 1.31358 19.7716 2.18054 19.7716H14.7909C15.6579 19.7716 16.3672 18.98 16.3672 18.0125V3.9398C16.3672 2.97231 15.6579 2.18072 14.7909 2.18072ZM14.7909 18.0125H2.18054V6.57843H14.7909V18.0125Z"
                    fill="#B9B9B9"
                />
              </svg>
            </a>
             <input type="date" ref="dateinput" @change="setDate" />
        </div>
    </section>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { nextTick } from 'vue';
export default {
    props: ['modelValue', 'title', 'disabled', 'required'],
    
    data() {
        return {
            error: false,
            reset: true
        };
    },
    watch: {
        modelValue: {
            handler(val) {
                nextTick(() => {
                this.setDateDefault()
                    // disable error when value is empty and mutated outside this component
                    if (!val && this.error) {
                        this.error = false;
                    }
                });
            },
            immediate: true,
        },
    },

    computed: {
        formattedDate: {
            get() {
                return this.modelValue;
            },
            set(v) {
                if (v) {
                    v = v.replace(/[^0-9\/]/g, '');
                }
                // Set empty string to null
                v = v || null;

                this.$refs.textinput.value = v;
                this.$emit('update:modelValue', v);
            },
        },
    },

    methods: {
        // Replicate date validation for resuable component, don't use mixins
        dateValid(date, format = 'M/D/YYYY') {
            if (!date) {
                return false;
            }

            if (format === 'M/D/YYYY' && date.match(/^\d{1,2}(\/)\d{1,2}(\/)\d{4}$/)) {
                const [m, d, yyyy] = date.split('/');
                date = [parseInt(m), parseInt(d), yyyy].join('/');
            }

            // days(date, format, [Boolean]) - Boolean strict validation
            if (dayjs(date, format, true).isValid()) {
                return true;
            } else {
                return false;
            }
        },

       // Set and auto append forward slash
        setDateText(e) {
            let v = e.target.value.trim();
             v = v.replace(/[^0-9\/]/g, '');

            const length2 = v.length === 2 && (/^\d{2}$/).test(v);
            const length5 = v.length === 5 && (/^\d{2}(\/)\d{2}$/).test(v);
            const triggerLength = length2 || length5 
            const typedIsNumber = (/[0-9]/g).test(e.key.toString());

            if(typedIsNumber && triggerLength) {
                    v += '/';
            }

            this.formattedDate = v;
        },

        sanitize(e) {
            const date = e.target.value;

            if (!date) {
                this.error = false;
                return;
            }

            // First validation
            if (!/^\d{1,2}(\/)\d{1,2}(\/)\d{2,4}$/.test(date)) {
                this.error = true;
                // this.$refs.dateinput.value = new Date();
                this.$emit('update:modelValue', date);
                return;
            } else {
                this.error = false;
            }

            let [m, d, y] = date.split('/');
            y = y.length === 2 ? `20${y}` : y;
            m = parseInt(m);
            d = parseInt(d);

            const formatted = [m, d, y].join('/');

            // Second validation, check for validation using dayjs. 13 val for months is invalid, 32 val for days is invalid
            if (!this.dateValid(formatted)) {
                this.error = true;
                // this.$refs.dateinput.value = new Date();
                this.$emit('update:modelValue', date);
                return;
            }

            this.formattedDate = formatted;
        },

        showDate() {
            const date = this.modelValue;

            if(this.error && this.$refs.dateinput.value === '') {
                // To Do - find valid value for empty date input. we can't use null, empty or date.now. 
                // this.$refs.dateinput.valueAsDate = null
            } 

            if (!date || this.dateValid(date)) {
                this.error = false;
            } else {
                this.error = true;
            }

            this.$refs.dateinput.showPicker();
        },

        setDate(e) {
            let formattedDate = null;
            if (e.target.value) {
                let [y, m, d] = e.target.value.split('-');
                formattedDate = `${parseInt(m)}/${parseInt(d)}/${y}`;
                this.error = false;
            }
            this.formattedDate = formattedDate;
        },

        setDateDefault() {
           if (/\d\d?\/\d\d?\/\d{2}(\d{2})$/g.test(this.modelValue) && dayjs(this.modelValue).isValid()) {
                this.$refs.dateinput.value = dayjs(this.modelValue).format('YYYY-MM-DD');
            }
        },
    },
};
</script>

<style scoped lang="scss">
$inputPlaceHolder: rgba(57, 57, 57, 0.5);
$borderColor: #878787;
$textColor: #3f3f3f;
$inputFocus: rgb(4, 127, 255);
$textRed: #dd2c01;
$inputBg: rgba(241, 241, 241, 1);
$inputBgFocus: rgba(249, 249, 249, 1);

section {
    position: relative;
    text-align: left;
    color: rgba(62, 62, 62, 1);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    label {
        line-height: 18px;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        padding-bottom: 3.4px;
    }
    .container {
        width: 100%;
        height: 41.18px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 16px;
        background: $inputBg;
        border-radius: 4px;
        input[type='text'] {
            background: transparent;
            width: 100%;
            height: 100%;
            border: none;
            text-align: left;
            padding-left: 13.4px;
            padding-right: 10.4px;
            font-size: 14px;
        }
        input + div {
            width: 100%;
            height: 100%;
            position: absolute;
            border: 1px solid $borderColor;
            // border-radius: 11.7184px;
            border-radius: 4px;
            pointer-events: none;
        }
        svg {
            margin-right: 13.4px;
            cursor: pointer;
        }
        input[type='date'] {
            // display: none;
            visibility: hidden;
            width: 0 !important;
            height: 0 !important;
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
        }
        input.error + div {
            border: 2px solid $textRed;
        }
        input:focus + div {
            border: 2px solid $inputFocus !important;
        }
    }

    section.disabled {
        * {
            pointer-events: none;
        }
        .container {
            background: rgba(232, 232, 232, 1);
        }
        input + div {
            border-color: transparent;
        }
    }
}
</style>
