<template>
    <section>
        <span>
            <template v-for="(item, i) in toast" :key="i">
                <div class="toast">
                    <div class="x">
                        <a href="#" @click.prevent="remove(i)">
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.50131 7.31072C4.25907 8.56048 3.04555 9.78155 1.83725 11C1.22396 10.3843 0.621115 9.77894 0.0182681 9.17102C1.22135 7.963 2.44009 6.74454 3.66145 5.51826C2.42966 4.28676 1.21091 3.0657 0 1.85247C0.613286 1.23672 1.21613 0.631404 1.83464 0.0104364C3.04555 1.2315 4.25907 2.45517 5.48826 3.69189C6.72788 2.44473 7.9414 1.22106 9.15231 0C9.77604 0.628795 10.3789 1.23411 10.9817 1.84203C9.78126 3.04744 8.56251 4.2685 7.35421 5.48174C8.58078 6.71063 9.79431 7.92908 11 9.13971C10.3789 9.76067 9.77082 10.366 9.15231 10.987C7.95445 9.78416 6.74093 8.56048 5.50131 7.31072Z"
                                    fill="white"
                                />
                            </svg>
                        </a>
                    </div>
                    <div class="text">
                           <span v-html="(item.msg) ? item.msg : 'An unexpected error has occurred'"></span>
                    </div>
                </div>
            </template>
        </span>
    </section>
</template>

<script>
export default {
    methods: {
        remove(i) {
            this.toast.splice(i, 1);
        },
    },

    computed: {
        toast: {
            get() {
                return this.$store.state.toast.rows;
            },
        },
    },
};
</script>

<style scoped lang="scss">
section {
    pointer-events: none;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 74px;
    padding-right: 20px;

    .toast {
        position: relative;
        pointer-events: auto;
        min-width: 343px;
        max-width: 500px;
        min-height: 98px;
        background: #ff764a;
        box-shadow: 0px 4px 31px rgba(138, 138, 138, 0.25);
        border-radius: 9px;
        color: white;
        display: flex;
           padding: 30px;
           padding-left: 20px;
           flex-direction: column;
           margin-bottom: 10px;
        .x {
            position: absolute;
            top: 10px;
            right: 12px;
            width: 19px;
            height: 19px;
            border-radius: 50%;
              display: flex;
                justify-content: center;
                align-items: center;
           &:hover {
            background: rgba(255, 255, 255, 0.5);
           }
            a {
                 display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                cursor: pointer;
            }

        }
        .text {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
