<template>
    <section v-if="pagination && pagination.total">
        <ul class="flex items-center justify-center space-x-1">
            <li v-if="false">
                <a :class="[buttonsClass, +pagination.from < 0 ? 'disabled' : '']" @click="fetchData(1)">
                    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.50915 8.21924L3.56872 4.87923L6.52197 1.36312" stroke="#313962" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M0.633545 8.27515L0.633544 1.36312" stroke="#313962" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
            </li>
            <li>
                <div style="padding-left: 15.76px; padding-right: 12.3px">
                    <a :class="[buttonsClass, +pagination.from < 0 ? 'disabled' : '']" @click="fetchData(pagination.current_page - 1)">
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.8007 9.18115L0.97718 5.12318L4.81738 0.851249" stroke="#313962" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </a>
                </div>
            </li>
            <li>
                <div>
                    Page <span class="font-bold">{{ pagination.current_page+1 }}</span> of <span class="font-bold">{{ pagination.last_page+1}}</span>
                </div>
            </li>
            <li>
                <div style="padding-right: 15.76px; padding-left: 12.3px">
                    <a   :class="[buttonsClass, +pagination.current_page >= +pagination.last_page ? ' disabled ' : '']" class="text-center" @click="fetchData(pagination.current_page + 1)">
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.46395 0.851318L5.28747 4.9093L1.44727 9.18122" stroke="#313962" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </a>
                </div>
            </li>
            <li v-if="false">
                <a :class="[buttonsClass, +pagination.current_page >= +pagination.last_page ? ' disabled ' : '']" @click="fetchData(pagination.last_page)">
                    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.755503 0.813232L3.69593 4.15324L0.742676 7.66935" stroke="#313962" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.6311 0.757324L6.6311 7.66935" stroke="#313962" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        pagination: {
            required: true,
        },
    },

    data() {
        return {
            buttonsClass: 'disabled:opacity-50 disabled:cursor-default',
        };
    },

    computed: {},

    methods: {
        fetchData(page) {
            const conds = (page === this.pagination.current_page) ||(page > this.pagination.last_page) || (page === -1);

            if(conds) {
                return
            }
            page = page.toString().replace(/[^0-9]/g, '');
            if (page == null || page == '') {
                return false;
            }
            this.$emit('fetchData', { page });
        },
    },
};
</script>
<style scoped lang="scss">
section {
    position: relative;
    color: rgba(49, 57, 98, 1);
    user-select: none;
    display: flex;
    align-items: center;
    font-size: 12px;
    .current-page {
        position: absolute;
        right: 0;
        left: 0;
        top: -7px;
    }
    svg {
        width: 12px;
        height: 12px;
    }
    li {
        padding: 0px;
        text-align: center;
        height: 100%;
        button,
        input {
            height: 100%;
            padding-left: 0.25rem !important;
            padding-right: 0.25rem !important;
            margin-top: 0 !important;
            width: 50px;
            text-align: center;
        }
        a {
            border-radius: 4px;
            width: 22.89px !important; 
            height: 22.89px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            svg {
                background: transparent;
            }
        }
        a.disabled {
            cursor: default;
            pointer-events: none;
        }

        a.disabled > * {
            opacity: 0.5;
        }
      
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

       a:not(.disabled):hover {
            background: #E2E2E2 !important;
        }
       a:not(.disabled):active {
            background:#D3D3D3 !important;
        }
}
</style>
