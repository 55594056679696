<template>
  <section v-if="pagination && pagination.total" class="space-x-1">
    <span class="pr-1"> Per Page</span>
    <div class="select-wrapper">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.4953 1.41222L6.21819 6.35998L11.6245 1.34131" stroke="#E4E4E4"/>
      </svg>

      <select
        v-model="perPage"
        @change="selectOption($event.target.value)"
        class="border border-gray-300 rounded-md leading-tight bg-gray-200 h-full"
      >
        <option v-for="(option, index) in options" :value="option" :key="index">
          {{ option }}
        </option>
      </select>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    pagination: {
      required: true,
    },
  },

  data() {
    return {
      options: [ 50, 100, 250, 500],
    };
  },

  computed: {
    perPage: {
      get() {
        if (!!this.pagination.per_page) {
          return this.pagination.per_page;
        } else {
          return 100;
        }
      },
      set(value) {
        return value;
      },
    },
  },

  methods: {
    selectOption(perpage) {
      return this.$emit("fetchData", { perpage });
    },
  },
};
</script>

<style scoped lang="scss">
section {
  color: black;
  display: flex;
  font-size: 14px;
  align-items: center;
  select {
    min-width: 70px;
    -webkit-appearance: none;
    appearance: none;
    height: 26.96px;
    z-index: 10;
    padding-left: 10px;
    position: relative;
    background: transparent;
   border: 1px solid #E4E4E4;
   border-radius: 0;
    cursor: pointer;

  }
  .input-group {
    min-width: 180px;
  }

  .select-wrapper {
    position: relative;
    height: 100%;
  }

  .select-wrapper svg {
    content: "▼";
    font-size: 1rem;
    top: 0px;
    bottom: 0px;
    color: #e4e4e4;
    height: 26.96px;
    right: 10px;
    position: absolute;
    z-index: 0;
  }
}
</style>
